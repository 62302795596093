.sign-container-component {
  text-align: center;
  height: 100vh;
}

.sign-container2 {
  width: 100%;
  height: 100%;
  position: relative;
  background: transparent;
  color: white;
}

.sign-container-welcome {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 32px;
  position: relative;
  top: 219px;
  color: #FFFFFF;
  padding-bottom: 90px;
}

.sign-container-form-login {
  /* background-color: green; */
  width: 100%;
  height: 100%;
  z-index: 1;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.logo-mymed {

  height: 144px;
}

/* para dispositivos small */
@media only screen and (max-width: 900px) {
  .sign-container-form {
    /* background-color: green; */
    margin-top: 35px;
  }
}

/* para dispositivos desktop */
@media only screen and (min-width: 900px) {
  .sign-container-form {
    /* background-color: green; */
   /* margin-top: -50px;*/
  }
}