.logo-container {
  background: #09101d;
  width: 100%;
}

.container-bg {
  background: #09101d;
}

.container-bg h1,
.container-bg h3 {
  text-align: center;
}

.container-bg .ellipse1 {
  /* background: #0000000f; */
  overflow: hidden;
  position: absolute;
  z-index: 2;
}

.container-bg .ellipse2 {
  /* background: #0000000f; */
  overflow: hidden;
  position: absolute;
  z-index: 2;
}

.container-content {
  position: relative;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  z-index: 10;
  flex-direction: column;
}

.container-content img {
  width: 195px;
  height: 195px;
}

/* para dispositivos extra small */
@media only screen and (max-height: 642px) and (max-width: 900px) {
  .logo-container {
    display: none;
  }
}

/* para dispositivos small */
@media only screen and (max-width: 900px) {
  .logo-container {
    height: 220px;
    align-self: flex-start;
    position: absolute;
  }

  .container-bg .curve {
    position: relative;
    display: block;
    height: 145px;
    bottom: -140px;
    margin-top: -120px;
    overflow: hidden;
    z-index: 1;
  }

  .container-bg .curve::after {
    box-shadow: inset 0 -10px 10px rgba(0, 0, 0, 0.05);
    height: 100px;
    bottom: 0;
    transform: translate(-5%, -100%);
    -ms-transform: translate(-5%, -100%);
    -webkit-transform: translate(-5%, -100%);
    content: "";
    position: absolute;
    width: 110%;
    z-index: -1;
    background: #09101d;
  }

  .container-bg .ellipse1 {
    width: 215px;
    height: 220px;
    border-radius: 50%;
    left: -70px;
    top: -40px;
  }

  .container-bg .ellipse2 {
    width: 300px;
    height: 250px;
    left: -95px;
    top: -55px;
    border-radius: 50%;
  }

  .container-content {
    transform: translateY(23%);
  }
}

/* para dispositivos desktop */
@media only screen and (min-width: 900px) {
  .logo-container {
    height: 100%;
  }
  .container-bg {
    position: relative;
    color: #fff;
    height: 100%;
  }

  .container-bg .ellipse1 {
    width: 100%;
    height: 70%;
    border-radius: 0px 0px 100% 100%;
  }

  .container-bg .ellipse2 {
    position: absolute;
    width: 100%;
    height: 85%;
    border-radius: 0px 0px 100% 100%;
  }

  .container-content {
    width: 100%;
    height: 100%;
  }
}
