/* First-level list */
.primaryList {
  list-style-type: disc; 
  padding-left: 20px; 
}

.primaryList > li {
  margin-bottom: 8px; 
}

/* Second-level list */
.secondaryList {
  list-style-type: circle; 
  padding-left: 40px;
}

.secondaryList > li {
  margin-bottom: 4px; 
}