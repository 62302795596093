.principalLayout {
  height: 100vh;
  width: 50vw;
  background: #fff;
  padding: 25px;
  overflow-y: auto;
}

.principalLayout2 {
  height: 100vh;
  width: 50vw;
  background: #09101d;
  color: #fff;
}
.logoMyMed {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 90vh;
}
.principalLayoutSection {
  display: flex;
  align-items: center;
  justify-items: center;
}

@media (max-width: 640px) {
  .principalLayout {
    height: max-content;
    width: 100vw;
  }
}
