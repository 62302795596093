.root {
  backdrop-filter: brightness(20%);
}

.helpVideos {
  height: 100vh;
  position: relative;
  width: 100vw;
}
.helpVideos img {
  display: block;
  border-color: white;
}

.helpVideos .helpVideosHeader {
  height: 386px;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, #3b8aff 0%, #0048b3 100%)
}

.helpVideos .helpVideosHeader .helpVideosHeaderRow {
  padding-top: 47px;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 1076px;
}

.helpVideos .helpVideosHeader .helpVideosHeaderRow .helpVideosHeaderIcon {
  align-items: center;
  color: white;
  display: grid;
  grid-template-columns: 77px 237px auto;
}

.helpVideos .helpVideosHeader .helpVideosHeaderRow .helpVideosHeaderIcon  label:nth-child(2) {
  justify-self: left;
  font-style: normal;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
}

.helpVideos .helpVideosHeader .helpVideosHeaderRow .helpVideosHeaderIcon  label:nth-child(3) {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  justify-self: right;
}

.helpVideos .helpVideosHeader .helpVideosHeaderRow .helpVideosHeaderIcon  label:nth-child(3) svg {
  height: 24px;
  vertical-align: text-bottom;
  width: 24px;
}
        

.helpVideos .helpVideosHeader .helpVideosHeaderTitle {
  padding-top: 33px;
  text-align: center;
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
}

.helpVideos .helpVideosHeader .helpVideosHeaderMessage {
  color: white;
  padding-top: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  margin: 0 auto;
  text-align: center;
  width: 700px;
}

.helpVideos .helpVideosCard {
  position: absolute;
  width: 70%;
  height: auto;
  background: #ffffff;
  border: 1px solid #dadee3;
  box-sizing: border-box;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 60px;
  padding: 20px;
}

.helpVideos .helpVideosCardTitle {
  grid-column: 1/4;
}

.helpVideos .helpVideosCardTitle .helpVideosCardTitleBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}

.helpVideos .helpVideosCardTitle .helpVideosCardTitleBack .helpVideosCardTitleBackBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #000094;
  border-radius: 32px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  font-weight: bold;
  font-size: 20px;
}

.helpVideos .helpVideosCardTitle .helpVideosCardTitleBack .helpVideosCardTitleBackLabel {
  color: #000094;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
}

.helpVideos .helpVideosCardTitle .helpVideosCardTitleLabel {
  position: static;
  left: 0px;
  top: 68px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  align-items: center;
  color: #000094;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 32px 0px -50px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #000094;
}

.helpVideos .helpVideosCardTitle .helpVideosCardTitleSearch {
  display: inline;
}

.helpVideos .helpVideosCardTitleBack {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.helpVideos .helpVideosCardTitleBack .helpVideosCardTitleBackBtn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  position: static;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #000094;
  border-radius: 32px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  font-weight: bold;
  font-size: 20px;
}

.helpVideos .helpVideosCardTitleBack .helpVideosCardTitleBackLabel {
  cursor: pointer;
  color: #000094;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
}
  

.helpVideos .helpVideosCardTitleLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #000094;
  margin: 32px 0px -50px 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #000094;
}

.helpVideos .helpVideosCardTitleSearch {
  margin-top: 70px;
  display: flow-root;
}

.helpVideos .helpVideosCardTitleSearch .helpVideosCardTitleSearchInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;

  position: static;
  width: 200px;
  height: 48px;
  left: 0px;
  top: 0px;

  /* Neutral / Neutral 9 */

  background: #f4f6f9;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  color: #09101D;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-left: 5px;
}
    
.helpVideos .helpVideosCardTitleSearch .helpVideosCardTitleSearchSelect {
  align-items: flex-start;
  padding: 4px 16px;
}

.helpVideos .helpVideosCardTitleSearch .helpVideosCardTitleSearchSelect select {
  grid-area: select;
  position: static;
  width: 239px;
  height: 48px;
  left: 0px;
  top: 0px;

  background: #f4f6f9;
  border-radius: 8px;

  cursor: inherit;
  line-height: inherit;
  outline: none;
  align-items: center;
  border: 1px;
  color: #09101D;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 5px;
}

.helpVideos .helpVideosCardTitleSearch .helpVideosCardTitleSearchSelect select option {
  color: #656b72;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding-left: 5px;
}
      

.helpVideos .helpVideosCardTitleSearch .helpVideosCardTitleSearchSelect::after {
  content: '';
  width: 1em;
  height: 0.7em;
  grid-area: select;
  margin-top: 20px;
  margin-right: 15px;
  background-color: #858c94;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}
  
/* para dispositivos small */
@media only screen and (max-width: 760px) {
  .testClass{
    display: inline-block;
  }
  .helpVideos .helpVideosCard {
    width: 90%;
  }
}