* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  .VerificationCode {
    font-family: sans-serif;
    text-align: center;
    background: #FFFFFF;
    height: 80vh;
    padding: 70px;
  }

  .ReactInputVerificationCode__item {
    font-size: 15px I !important;
    font-weight: 400  !important;
    color: #999999  !important;

    background: white;
    border: 1px  !important;
    border-radius: 10px !important;
    box-shadow: none;
  }
  