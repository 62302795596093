.custom-alert-error {
    background: #FEEFEF!important;
    color: #DA1414!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 24px!important;
    border-radius: 8px;
    text-align: left;   
}

.custom-alert-error .MuiAlert-icon {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
}