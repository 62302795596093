.videoCard {
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.22);
  background: #ffffff;
  border: 1px solid #dadee3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.videoCard .videoCardVideo {
  border-radius: 8px 8px 0px 0px;
  height: 175px;
  background-color: #0a2e4e;
}

.videoCard .videoCardVideo .videoCardVideoPlayBtn {
  border-radius: 50%;
  background-color: white;
  height: 60px;
  width: 60px;
  position: absolute;
  margin-top: 67px;
  margin-left: 21%;
  cursor: pointer;
  padding-top: 12px;
  padding-left: 12px;
  font-size: 37px;
  color: #0a2e4e;
}

.videoCard .videoCardSummary {
  margin-left: 15px;
  margin-right: 30px;
}

.videoCard .videoCardSummary .videoCardSummaryCategory {
  position: static;
  width: 194px;
  height: 20px;
  left: 40px;
  top: 0px;

  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  color: #f01974;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.videoCard .videoCardSummary .videoCardSummaryTitle {
  position: static;
  width: 230px;
  height: 48px;
  left: 0px;
  top: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #09101d;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 14px 0px;
}

.videoCard .videoCardSummary .videoCardSummaryDescription {
  position: static;
  height: 70px;
  left: 24px;
  top: 120px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #394452;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 20px 0px;
}

.videoCard .videoCardSummary .videoCardSummaryDate {
  position: static;
  height: 20px;
  left: 0px;
  top: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #394452;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.videoPlayer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}





/* para dispositivos small */
@media only screen and (max-width: 760px) {
  .videoCard .videoCardVideo .videoCardVideoPlayBtn {
    left: 23%;
  }
  .videoPlayerFrame {
    width: 100%;
  }
}
