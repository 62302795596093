.qr-close {
    position: fixed;
    left: 0;
    bottom: 25px;
    width: 100%;
}

.qr-reader section div {
    max-height: 600px;
    box-shadow: none !important;
}

.qr-reader section video {
    max-height: 600px;
}