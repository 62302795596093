/* Card personal info */
.personalInfoCardPage{
  display: flex;
  justify-content: center;
  align-items: center;
}

.personalInfoCardPage .personalInfo {
  flex-direction: row;
  gap: 10px;
  width: 95%;
  margin-top: 30px;
}

.personalInfoCardPage .personalInfo .personalInfoData{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #432C81;
}

.personalInfoCardPage .personalInfo .personalInfoData p{
  margin-bottom: 10px;
}

.personalInfoCardPage .personalInfo .personalInfoData p span{
  font-weight: 400;
}

/* Organ donor and blood type */
.personalCardQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 95%;
  margin: auto;
  margin-bottom: 15px;
}

.personalCardQuestion .question {
  flex-direction: row;
  padding: 30px 16px;
  gap: 10px;
  width: 47%;
  margin-top: 20px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #432C81;
}

.personalCardQuestion .question p{
  margin-bottom: 10px;
  font-weight: 600;
}

.personalCardQuestion .question span{
  font-weight: 400;
}


/* Personal medical information */
.personalInfoCardPage .personalInfoDataMedical {
  flex-direction: row;
  padding: 25px 16px;
  gap: 10px;
  width: 90%;
  margin-top: 20px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  text-align: center;
  font-family: 'Source Sans Pro';
  font-style: normal;
  color: #000000;
}

.personalInfoCardPage .personalInfoDataMedical .name{
  font-weight: 600;
  font-size: 31px;
  line-height: 35px;
  margin-bottom: 5px;
}

.personalInfoCardPage .personalInfoDataMedical .dob{
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.personalInfoCardPage .personalInfoDataMedical .image{
  display: flex;
  margin: auto;
}

@media (min-width: 900px) {
  .personalInfoCardPage .personalInfoDataMedical .memberId{
    display: flex;
  }
}

@media (max-width: 900px) {
  .personalInfoCardPage .personalInfoDataMedical .imageOrgandonor{
    display: flex;
    color: red;
    margin: auto;
  }
}

.personalInfoCardPage .personalInfoDataMedical .memberId{
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0px;
  text-align: center;
}

/* Medical information */
.personalInfoCardPage .personalInfoMedical {
  font-family: 'Source Sans Pro';
  font-style: normal;
  text-align: initial;
}

.personalInfoCardPage .personalInfoMedical .grid{
  margin-bottom: 14px;
  padding: 0px 10px;
}

.personalInfoCardPage .personalInfoMedical .grid .title{
  color: #000094;
  padding: 15px 0px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  border-bottom: 1px solid #000094;
  margin-bottom: 16px;
}

.personalInfoCardPage .personalInfoMedical .grid ul{
  padding: 0px 18px;
}

.personalInfoCardPage .personalInfoMedical .grid ul li{
  margin-bottom: 5px;
}

.personalInfoCardPage .personalInfoMedical .grid .title .history{
  height: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 5px;
}

/* Medical documents information */
.personalInfoCardPage .personalInfoMedical .grid .medicalDescription {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #858C94;
}

/* Modal */
.modal {
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 16px 25px 24px;
  position: absolute;
  background: #FFFFFF;
  border-radius: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  text-align: center;
}

.modal .modalTitle{
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  min-width: 260px;
  text-transform: uppercase;
  color: #000094;
  padding: 11px 0px 8px;
  border-bottom: 1px solid #000094;
}

.modal .modalSubtitle{
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.modal .seconds{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000094;
}

.modal .modalButtons{
  text-transform: none;
  color: #fff; 
  background-color: #000094;
  margin: 15px 9px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 9px 16px;
  gap: 8px;
}












