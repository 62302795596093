.container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 90vh;
}
.appLogo {
  width: 70px;
  height: 70px;
  left: 178.5px;
  top: 1px;
}

.buttonStyleDiv {
  background-color: #000094;
  color: white !important;
  width: 100%;
  font-size: 1.42857em;
  height: 64px;
  border-radius: 8px;
  text-align: center;
}

.buttonStyleDivWhite {
  background-color: #ffffff;
  border: 1px solid #000094;
  color: white !important;
  width: 100%;
  font-size: 1.42857em;
  height: 64px;
  border-radius: 8px;
  text-align: center;
}

.buttonStyle {
  background: none;
  color: white;
  font-size: 19px;
  padding: 23px;
}

.text {
  font-size: 20px;
}

.title {
  font-size: 69px;
  color:#000094;
  font-weight: bold;
  margin: inherit;
}

.subtitle {
  font-size: 40px;
  color:#000094;
}
  

.rowPassword {
  margin-top: 20px;
}
.errorFormik {
  position: relative;
  top: -15px;
  color: #9f3a38;
  font-size: 12px;
}
@media (max-width: 640px) {
  .container {
    margin: 0 15px 0 24px;
    height: 100% !important;
  }

  .form {
    height: max-content;
  }

  .inputForm {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }

}
.passwordField {
  width: 100%;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 5px;
}

.labelFields {
  color: #000; 
  font-weight: 700;
}

.formInputs {
  height: 10px;
}

.labelFieldsTerms {
  color: #000; 
  font-weight: 700;
}

.labelAlreadyAccount {
  font-weight: 600;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}
.sucessIcon{
  text-align: center;
}
.buttonStyleDivWhiteSucessPage{
  background-color: #ffffff;
  border: 1px solid #000094;
  color: white !important;
  width: 30%;
  height: 64px;
  border-radius: 8px;
  text-align: center;
}