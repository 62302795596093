.sign-container {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  position: relative;
}

.sign-container-form {
  /* background-color: green; */
  width: 100%;
  height: 350px;
  z-index: 1;
  max-width: 420px;
  margin-top:5%;
}

/* para dispositivos small */
@media only screen and (max-width: 900px) {
  .sign-container-form {
    /* background-color: green; */
    margin-top: 95px;
  }
}

/* para dispositivos desktop */
/* @media only screen and (min-width: 900px) {

} */
